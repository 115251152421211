import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import App2 from './App2';
import App3 from './App3';
import firebase from 'firebase';

var firebaseConfig = {
  apiKey: 'AIzaSyAgc9UoWFNcWNZcdbkbNf1ZAG4m7SZi1p0',
  authDomain: 'fir-1-e8848.firebaseapp.com',
  projectId: 'fir-1-e8848',
  storageBucket: 'fir-1-e8848.appspot.com',
  messagingSenderId: '424244047007',
  appId: '1:424244047007:web:a31418c96bf2404bdc86aa',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App3 />
  </React.StrictMode>,
  document.getElementById('root'),
);
