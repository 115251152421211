// -------------------------------- CRUD firestore ---------------------------------

import firebase from 'firebase';
import { useForm } from 'react-hook-form';

function App2() {
  const db = firebase.firestore();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // добавление с id по умолчанию -----------------------------------------
  // const onSubmit = (data) => {
  //   console.log(data);
  //   db.collection('users')
  //     .add(data)
  //     .then((docRef) => {
  //       console.log('документ сохранен с id ', docRef.id);
  //     })
  //     .catch((er) => console.log(er));
  // };

  // добавление с кастомным id --------------------------------------------
  // const onSubmit = (data) => {
  //   console.log(data);
  //   db.collection('users')
  //     .doc(data.roll)
  //     .set(data)
  //     .then(() => {
  //       console.log('документ сохранен с id ', data.roll);
  //     })
  //     .catch((er) => console.log(er));
  // };

  // исправление update --------------------------------------------
  // const onSubmit = (data) => {
  //   console.log(data);
  //   db.collection('users')
  //     .doc(data.roll)
  //     .update(data)
  //     .then(() => {
  //       console.log(`документ с id  ${data.roll} изменён`);
  //     })
  //     .catch((er) => console.log(er));
  // };

  // удаление delete --------------------------------------------
  // const onSubmit = (data) => {
  //   console.log(data);
  //   db.collection('users')
  //     .doc(data.roll)
  //     .delete()
  //     .then(() => {
  //       console.log(`документ с id  ${data.roll} удалён`);
  //     })
  //     .catch((er) => console.log(er));
  // };

  // ПРОЧИТАТЬ ДАННЫЕ --------------------------------------------
  const onSubmit = (data) => {
    db.collection('users')
      .doc(data.roll)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
        } else {
          console.log('не найдено');
        }
      })
      .catch((er) => console.log(er));
  };

  return (
    <div className="container">
      <h2>app2</h2>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {errors.name?.type === 'required' && 'First name is required'}
        {errors.name?.type === 'maxLength' && 'maxLength'}
        <input
          type="name"
          placeholder="name"
          name="name"
          {...register('name', { required: true, maxLength: 7 })}
        />
        {/* ---------- */}

        <input type="roll" placeholder="roll" name="roll" {...register('roll')} />
        {errors.section?.type === 'minLength' && 'minLength'}
        {/* ---------- */}
        <input
          type="section"
          placeholder="section"
          name="section"
          {...register('section', { minLength: 3 })}
        />
        {/* ---------- */}
        <input type="gender" placeholder="gender" name="gender" {...register('gender')} />
        {/* ---------- */}
        <button type="submit">send</button>
      </form>
    </div>
  );
}

export default App2;
