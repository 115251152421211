// ----------------------------- chat with googleAuth -----------------------------------
import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { useList } from 'react-firebase-hooks/database';

const App3 = () => {
  const [snapshots, loading, error] = useList(firebase.database().ref('posts'));
  // const [messageList, setMessageList] = useState('');
  const [message, setMessage] = useState('');
  const [isUser, setIsUser] = useState(false);
  const [nick, setNick] = useState('');
  const [photo, setPhoto] = useState('');

  // console.log(snapshots.length > 0 ? true : false);
  console.log(snapshots.map((item) => item.val()));

  // const getMessages = () => {
  //   var messagesRef = firebase.database().ref('posts');
  //   messagesRef.on('value', (snapshot) => {
  //     const data = snapshot.val();
  //     console.log(data);
  //   });
  // };

  const handleInput = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    // console.log(message);
    var postListRef = firebase.database().ref('posts');
    var newPostRef = postListRef.push();
    newPostRef.set({
      msg: message,
      userName: nick,
      photo: photo,
    });
    setMessage('');
  };

  const singInWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var name = result.user.displayName;
        var photo = result.user.photoURL;
        console.log(photo);
        setIsUser(true);
        setNick(name);
        setPhoto(photo);
      });
  };

  const singOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setIsUser(false);
        setNick('');
      });
  };

  return (
    <div className="container">
      <h2>App-3</h2>
      <div className="auth">
        {isUser ? (
          <div>
            <p>{nick}</p>
            <button className="btn" onClick={singOut}>
              ВЫЙТИ
            </button>
            <div className="chat">
              <div className="messages">
                {snapshots.length > 0 &&
                  snapshots.map((item) => (
                    <div className="msg-wrap">
                      <div
                        className={
                          item.val().userName === nick ? 'nick-wrap-me' : 'nick-wrap-alien'
                        }>
                        <div className="avatar">
                          <img src={item.val().photo} alt="" />
                        </div>
                        <div className="nick">{item.val().userName}</div>
                      </div>
                      <div className={item.val().userName === nick ? 'msg-me' : 'msg-alien'}>
                        {item.val().msg}
                      </div>
                    </div>
                  ))}
              </div>
              <input onChange={handleInput} type="text" placeholder="msg" value={message} />
              <button onClick={handleSubmit}>SEND MESSAGE</button>
            </div>
          </div>
        ) : (
          <button className="btn" onClick={singInWithGoogle}>
            ВОЙТИ
          </button>
        )}
      </div>
    </div>
  );
};
export default App3;
